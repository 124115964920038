import React from "react"
import SEO from "../components/seo"
import App from "../components/App"

const IndexPage = () => (
  <>
    <SEO title="Dnevnik" />
    <App />
  </>
)

export default IndexPage

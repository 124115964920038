import React from "react"
import "./layout.css"

const App = () => {


  return (
    <div style={{fontFamily: "Mukta", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", "alignItems": "center"}}>
      <h2 style={{fontFamily: "Mukta", marginBottom: "0px", marginTop: "0px"}}>Nikola Krstić</h2>
      <h1 style={{fontFamily: "Mukta", marginBottom: "15px", marginTop: "0px"}}>DNEVNIK</h1>
      <h4 style={{fontFamily: "Mukta", color: "#888888"}}>UNDER CONSTRUCTION</h4>
    </div>
  )
}

export default App
